import type { getPage as EmailPreview_getPage } from 'banner-typescript-rest-api/src/models/email-preview';
import type * as IntegrationDefinition from 'banner-typescript-rest-api/src/models/integration-definition';
import type * as SettingsCurrencies from 'banner-typescript-rest-api/src/models/settings-currencies';
import type * as SettingsNotifications from 'banner-typescript-rest-api/src/models/settings-notifications';
import type * as WorkerConfig from 'banner-typescript-rest-api/src/models/worker-config';
import type { getByTeamID as SubGroup_getByTeamID } from 'banner-typescript-rest-api/src/models/sub-group';
import type { getAllFeatures } from 'banner-typescript-rest-api/src/models/team';
import type { CustomTasksService } from 'banner-typescript-rest-api/src/services/CustomTasksService';
import type { ManagerProfileGroupService } from 'banner-typescript-rest-api/src/services/ManagerProfileGroupService';
import type { PlaidBalancesByBuildingService } from 'banner-typescript-rest-api/src/services/PlaidBalancesByBuildingService';
import { generateBuildingTags } from '../../common/buildings';
import type { ApprovalPackageController } from '../../server/controllers/approvalPackageController';
import type { ApprovalPackageHelper } from '../../server/controllers/approvalPackageHelper';
import type { AuditController } from '../../server/controllers/auditController';
import type { AutomationController } from '../../server/controllers/automationController';
import type { BuildingController, GetBuildingsParams } from '../../server/controllers/buildingController';
import type { ChangeOrderController } from '../../server/controllers/changeOrderController';
import type { ContractsController } from '../../server/controllers/contractsController';
import type { DataAssociationController } from '../../server/controllers/dataAssociationController';
import type { DrawReportController } from '../../server/controllers/drawReportController';
import type { IntegrationController } from '../../server/controllers/integrationController';
import type { LogsController } from '../../server/controllers/logsController';
import type { PermissionController } from '../../server/controllers/permissionController';
import type { ProfileController } from '../../server/controllers/profileController';
import type { ProjectController } from '../../server/controllers/projectController';
import type { ShellController } from '../../server/controllers/shellController';
import type { ShellControllerHelper } from '../../server/controllers/shellControllerHelper';
import type { TeamController } from '../../server/controllers/teamController';
import type { SrcServerRes as JsRes, ServerTsRes as TsRes, _MakeApiFns } from './api-context';

export default function _apiFnsRead(makeApiFns: _MakeApiFns) {
  const { get, post, extend } = makeApiFns;

  // prettier-ignore
  // keeping these all single line so we can easily use VSCode "Sort Ascending" feature
  const plainApiCalls = {
    admin_getEmailPreview: get('/api/admin/email-preview')<TsRes<typeof EmailPreview_getPage>>(),
    admin_getFeatureList: get('/api/admin/feature-list')<TsRes<typeof getAllFeatures>>(),
    '/api/approvals/searchable-manager-ids': get('/api/approvals/searchable-manager-ids')<TsRes<ManagerProfileGroupService['approvalsSearchableManagerIds']>>(),
    '/api/contractors/getActiveBids': get('/api/contractors/getActiveBids')<JsRes<ProjectController['getContractorActiveBids']>>(),
    contractors_getContractorBidForProject: get('/api/contractors/getContractorBidForProject')<JsRes<ProjectController['getContractorBidForProject']>>(),
    '/api/contractors/getProfile': get('/api/contractors/getProfile')<JsRes<ProfileController['getVendorProfile']>>(),
    '/api/custom-tasks/list-task-types': get('/api/custom-tasks/list-task-types')<TsRes<CustomTasksService['loadTaskTypes']>>(),
    '/api/custom-tasks/list-tasks': get('/api/custom-tasks/list-tasks')<TsRes<CustomTasksService['loadTasksPage']>>(),
    '/api/managers/getAwardedBidForProject': get('/api/managers/getAwardedBidForProject')<JsRes<ProjectController['getAwardedBidForProject']>>(),
    getAwardedBidForProject: get('/api/managers/getAwardedBidForProject')<JsRes<ProjectController['getAwardedBidForProject']>>(),
    getBidsByProjectID: get('/api/managers/getBidsByProjectID')<JsRes<ProjectController['getBidsForProject']>>(),
    '/api/managers/getBidsForBuildings': post('/api/managers/getBidsForBuildings')<JsRes<ProjectController['getBidsForBuildings']>>(),
    '/api/managers/getContractsForBuildings': post('/api/managers/getContractsForBuildings')<JsRes<ProjectController['getContractsForBuildings']>>(),
    '/api/managers/getCOsForBuildings': post('/api/managers/getCOsForBuildings')<JsRes<ChangeOrderController['getCOsForBuildings']>>(),
    '/api/managers/getManagerProfile': get('/api/managers/getManagerProfile')<JsRes<ProfileController['getManagerProfile']>>(),
    generateBH: get('/api/pdf/plus/generateBH')(),
    generateGreystar: get('/api/pdf/plus/generateGreystar')(),
    '/api/plus/data/getDataUploads': get('/api/plus/data/getDataUploads')<JsRes<ProjectController['getDataUploads']>>(),
    '/api/plus/errors': get('/api/plus/errors')<JsRes<LogsController['getErrors']>>(),
    '/api/plus/errors/count': get('/api/plus/errors/count')<JsRes<LogsController['getErrorsCount']>>(),
    '/api/plus/managers/fetchDynamicTagOptions': get('/api/plus/managers/fetchDynamicTagOptions')<ShellControllerHelper['fetchDynamicTagOptions']>(),
    '/api/plus/managers/getAllCompletedApprovalPackagesForTeam': get('/api/plus/managers/getAllCompletedApprovalPackagesForTeam')(),
    '/api/plus/managers/getApprovalPackage': get('/api/plus/managers/getApprovalPackage')<JsRes<ApprovalPackageHelper['getApprovalPackageForBid']>>(),
    getApprovalPackage_manager: get('/api/plus/managers/getApprovalPackage')<JsRes<ApprovalPackageHelper['getApprovalPackageForBid']>>(),
    '/api/plus/managers/getApprovalPackageDrafts': get('/api/plus/managers/getApprovalPackageDrafts')(),
    '/api/plus/managers/getApprovalPackagesForUser': get('/api/plus/managers/getApprovalPackagesForUser')<ApprovalPackageController['getApprovalPackagesForUser']>(),
    getBudgetRevisions: get('/api/plus/managers/getBudgetRevisions')<JsRes<ShellController['getBudgetRevisions']>>(),
    '/api/plus/managers/getBuilding': get('/api/plus/managers/getBuilding')<JsRes<BuildingController['getBuildingAPI']>>(),
    getBuildingsApi: get('/api/plus/managers/getBuildings')<JsRes<BuildingController['getBuildings']>, GetBuildingsParams>(),
    '/api/plus/managers/getChangeOrdersForProject': get('/api/plus/managers/getChangeOrdersForProject')<JsRes<ChangeOrderController['getChangeOrdersForProject']>>(),
    '/api/plus/managers/getInvoicesForProject': get('/api/plus/managers/getInvoicesForProject')<JsRes<ContractsController['getInvoicesForProject']>>(),
    '/api/plus/managers/getCoveredApprovalPackagesForUser': get('/api/plus/managers/getCoveredApprovalPackagesForUser')<JsRes<ApprovalPackageController['getCoveredApprovalPackagesForUser']>>(),
    '/api/plus/managers/getCustomTables': get('/api/plus/managers/getCustomTables')<JsRes<TeamController['getCustomTables']>>(),
    '/api/plus/managers/getDataValidation': get('/api/plus/managers/getDataValidation')<JsRes<ProjectController['getDataValidation']>>(),
    getDataValidation: get('/api/plus/managers/getDataValidation')<JsRes<ProjectController['getDataValidation']>>(),
    '/api/plus/managers/getDrawReportsForShell': get('/api/plus/managers/getDrawReportsForShell')<JsRes<DrawReportController['getDrawReportsForShell']>>(),
    '/api/plus/managers/getGlCodeMonthlyBudgets': get('/api/plus/managers/getGlCodeMonthlyBudgets')<JsRes<ShellController['getGlCodeMonthlyBudgets']>>(),
    '/api/plus/managers/getInvoicesForBuildingsFlat': post('/api/plus/managers/getInvoicesForBuildingsFlat')<JsRes<ContractsController['getInvoicesForBuildingsFlat']>>(),
    '/api/plus/managers/getInvoicesForShell': get('/api/plus/managers/getInvoicesForShell')<JsRes<ContractsController['getInvoicesForShell']>>(),
    '/api/plus/managers/getCompanyWideApprovals': get('/api/plus/managers/getCompanyWideApprovals')<JsRes<ApprovalPackageController['getCompanyWideApprovals']>>(),
    '/api/plus/managers/getInvoicesForShellFlat': get('/api/plus/managers/getInvoicesForShellFlat')<JsRes<ContractsController['getInvoicesForShellFlat']>>(),
    '/api/plus/managers/getNumberOfPendingTasks': get('/api/plus/managers/getNumberOfPendingTasks')<JsRes<ApprovalPackageController['getNumberOfPendingTasks']>>(),
    getReadableApprovalPackagesForUser: get('/api/plus/managers/getReadableApprovalPackagesForUser')<JsRes<ShellController['getReadableApprovalPackagesForUser']>>(),
    getSameContractGroupCurrentContractApprovals: get('/api/plus/managers/getSameContractGroupCurrentContractApprovals')<JsRes<ApprovalPackageController['getSameContractGroupCurrentContractApprovals']>>(),
    '/api/plus/managers/getShell': get('/api/plus/managers/getShell')<JsRes<ShellController['getShellEx']>>(),
    '/api/plus/managers/getShells': get('/api/plus/managers/getShells')<JsRes<ShellController['getShellsEx']>>(),
    getShellsPost: post('/api/plus/managers/getShellsPost')<JsRes<ShellController['getShellsEx']>>(),
    '/api/plus/managers/getSubGroups': get('/api/plus/managers/getSubGroups')<TsRes<typeof SubGroup_getByTeamID>>(),
    '/api/teams/getVendors': get('/api/teams/getVendors')<JsRes<TeamController['getTeamVendors']>>(),
    '/api/plus/managers/getTags': get('/api/plus/managers/getTags')<JsRes<TeamController['getTags']>>(),
    '/api/plus/managers/getTag': get('/api/plus/managers/getTag')<JsRes<TeamController['getTag']>>(),
    '/api/plus/managers/findTag': get('/api/plus/managers/findTag')<JsRes<TeamController['findTag']>>(),
    '/api/plus/managers/getTeam': get('/api/plus/managers/getTeam')<JsRes<TeamController['getTeam']>>(),
    '/api/plus/managers/getUnitsForBuilding': get('/api/plus/managers/getUnitsForBuilding')<JsRes<ShellController['getUnitsForBuilding']>>(),
    getUnitsForBuilding: get('/api/plus/managers/getUnitsForBuilding')<JsRes<ShellController['getUnitsForBuilding']>>(),
    getUnitsForBuildingShells: get('/api/plus/managers/getUnitsForBuildingShells')<JsRes<ShellController['getUnitsForBuildingShells']>>(),
    '/api/plus/managers/getValidApprovalFlow': get('/api/plus/managers/getValidApprovalFlow')<JsRes<ApprovalPackageController['getValidApprovalFlowAPI']>>(),
    '/api/plus/permissions/getUserFeaturesGroups': get('/api/plus/permissions/getUserFeaturesGroups')<JsRes<PermissionController['getUserFeaturesGroups']>>(),
    '/api/plus/teams/getActivities': get('/api/plus/teams/getActivities')<JsRes<TeamController['getActivities']>>(),
    '/api/plus/vendors/getUnitsForBuilding': get('/api/plus/vendors/getUnitsForBuilding')<JsRes<ShellController['getUnitsForBuilding']>>(),
    '/api/settings/account-balances-by-building': get('/api/settings/account-balances-by-building')<TsRes<PlaidBalancesByBuildingService['list']>>(),
    '/api/settings/account-balance-for-building': get('/api/settings/account-balance-for-building')<TsRes<PlaidBalancesByBuildingService['single']>>(),
    shared_getProjectByID: get('/api/shared/getProjectByID')<JsRes<ProjectController['getManagerProjectByID']>>(),
    '/api/teams/getTeamInvoices': get('/api/teams/getTeamInvoices')<JsRes<TeamController['getTeamInvoices']>>(),
    '/api/v3/settings/currencies/list': get('/api/v3/settings/currencies/list')<TsRes<typeof SettingsCurrencies.listAllCurrencies>>(),
    '/api/v3/listIntegrationDefinitions': get('/api/v3/listIntegrationDefinitions')<TsRes<typeof IntegrationDefinition.listIntegrationDefinitionsAndTokens>>(),
    '/api/plus/managers/checkInvoiceDuplication': post('/api/plus/managers/checkInvoiceDuplication')<JsRes<ContractsController['checkInvoiceDuplication']>>(),
    '/api/plus/managers/getTagAudits': get('/api/plus/managers/getTagAudits')<JsRes<AuditController['getTagAudits']>>(),
    '/api/plus/managers/getShellAudits': get('/api/plus/managers/getShellAudits')<JsRes<AuditController['getShellAudits']>>(),
    getNotificationSettings: get('/api/v3/settings/notifications')<TsRes<typeof SettingsNotifications.listNotifications>>(),
    '/api/plus/managers/listAutomationSettings': get('/api/plus/managers/listAutomationSettings')<JsRes<AutomationController['listAutomationSettings']>>(),
    '/api/shared/getProjectForDataValidation': get('/api/shared/getProjectForDataValidation')<JsRes<ProjectController['getManagerProjectDataValidation']>>(),
    '/api/plus/managers/getDataAssociationOptions': get('/api/plus/managers/getDataAssociationOptions')<JsRes<DataAssociationController['getOptions']>>(),
    '/api/integration/callArbitratyIntegrationMethod': post('/api/integration/callArbitratyIntegrationMethod')(),
    '/api/integration/getExportConfigurations': get('/api/integration/getExportConfigurations')<JsRes<IntegrationController['getExportConfigurations']>>(),
    '/api/plus/managers/listWorkerConfigsForAdmin': get('/api/plus/managers/listWorkerConfigsForAdmin')<TsRes<typeof WorkerConfig.listWorkerConfigsForAdmin>>(),
    
  }

  const { getBuildingsApi } = plainApiCalls;

  // transforming functions:

  const getBuildingsSimple = extend(getBuildingsApi, async (getBuildingsApi, params?, config?) => {
    const data = await getBuildingsApi(params, config);
    return data.buildings;
  });

  const getBuildingsWithTags = extend(getBuildingsApi, async (getBuildingsApi, params?, config?) => {
    const data = await getBuildingsApi(params, config);
    const { buildings, tags } = data;
    // Hydrate tags data into buildings to convert size-optimized backend model to frontend model
    return buildings.map((building) => ({
      ...building,
      ...generateBuildingTags(building, tags),
    }));
  });

  // (you can add more here if you like)

  return {
    ...plainApiCalls,
    getBuildingsSimple,
    getBuildingsWithTags,
  };
}
